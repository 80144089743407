@import url(https://fonts.googleapis.com/css?family=Noto+Sans);
@import url(//fonts.googleapis.com/earlyaccess/notosanssc.css);
body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  font-family: "Noto Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.bold {
  font-weight: bold;
}

.medium {
  font-weight: 500;
}

.italic {
  font-style: italic;
}

.noto-sans-sc {
  font-family: "Noto Sans SC", sans-serif;
}

/* For Mobile Phones */
@media only screen and (max-width: 768px) {
}


.nav-link,
.navDropDown .nav-link,
.navDropDownItem {
  color: #ffffff !important;
  font-size: 1em;
  padding-right: 10px !important;
}

.nav-link:hover,
.navDropDown .nav-link:hover {
  font-weight: bold;
  /* color: forestgreen !important; */
}

.navDropDownItem:hover {
  background-color: #0d6efd;
}

.dropdown-menu {
  width: auto;
  background-color: #4571c9;
  margin-top: -5px !important;
}

.mmtLogo {
  background-color: white;
  margin-top: -50px;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 16% 100%, 0% 44%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 16% 100%, 0% 44%);
}
/* For Mobile Phones */
@media only screen and (max-width: 768px) {
  .dropdown-menu {
    width: 100%;
    text-align: right !important;
    min-width: 40% !important;
  }
}

.section {
  width: 100%;
  text-align: center;
  justify-content: center;
}

.sectionTitle {
  font-size: 4vh;
  color: #234ea2;
  margin-bottom: 1rem;
}

.sectionBody {
  font-size: 2.5vh;
  color: black;
}

.discoverStory {
  line-height: 25px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.carousel-container {
  padding: 10px 0 20px 0;
}

.react-multi-carousel-item {
  padding: 0 25px 0 25px;
}

.card {
  cursor: pointer;
}

.productCard {
  max-width: 400px;
  max-height: 400px;
}

.card-img-overlay {
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 1.7em;
  color: white;
  word-spacing: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-multi-carousel-item:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.serviceCard {
  max-width: 600px;
  max-height: 600px;
}

.serviceCard:hover {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

.serviceCard:hover .card-img-overlay {
  font-size: 1.75em;
  color: #234ea2;
  background-color: rgba(255, 255, 255, 0.8);
}

.readMoreBtn:hover {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

/* For Mobile Phones */
@media only screen and (max-width: 768px) {
  .serviceCard {
    max-width: 300px;
    max-height: 300px;
    font-size: 0.8em;
  }

  .serviceCard:hover .card-img-overlay {
    font-size: 1.75em;
  }
}

.goToTopButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #234ea2;
}

.whatsapp-button {
  position: fixed;
  z-index: 999;
  right: 20px;
  bottom: 100px;
}

.whatsapp-icon {
  font-size: 1.5em;
}

* {
  transition: all 0.6s;
}

html {
  height: 100%;
}

body {
  font-family: "Lato", sans-serif;
  color: #888;
  margin: 0;
}

#main {
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.fof {
  display: table-cell;
  vertical-align: middle;
}

.fof h1 {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  -webkit-animation: type 0.5s alternate infinite;
          animation: type 0.5s alternate infinite;
}

@-webkit-keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}

.contentPart {
  padding-bottom: 0.5rem !important;
}

.contentPart p {
  line-height: 20px;
}

.aboutUsSectionBody {
  color: black;
  font-size: 1em;
}

.mileStoneBodyContainer {
  width: 100%;
  display: flex;
}

.timeline-item-title {
  font-size: 3vh !important;
  color: #234ea2 !important;
  word-spacing: 100vw;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-description {
  font-size: 2vh !important;
  color: black !important;
  align-items: flex-start !important;
}

.timeline-card-content {
  min-height: 200px !important;
}

.card-content-wrapper {
  width: 70% !important;
}

/* For Desktop and Tablet */
@media only screen and (min-width: 769px) {
  .card-description {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    margin-left: 15px !important;
    line-height: 30px;
  }
}

/* For Mobile Phones */
@media only screen and (max-width: 768px) {
  .mileStoneTitle {
    width: 100%;
    text-align: center;
  }

  .timeline-item-title {
    font-size: 2vh !important;
  }

  .css-mpnodf-TimelineTitleWrapper,
  .css-oq5ppo {
    width: 37% !important;
  }

  .card-content-wrapper {
    width: 53% !important;
  }

  .mobileVersion p {
    text-align: justify;
    text-justify: inter-word;
    text-indent: 30px;
  }
}

.businessOpp ul {
  list-style-position: inside;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  color: black;
  font-size: 1em;
  line-height: 30px;
}

.black {
  color: black;
  font-size: 1em;
}

.inputBox {
  width: 100%;
  border: 0.5px solid #234ea2;
}

.submitButton {
  float: right;
  color: #234ea2;
  border: 0.5px solid #234ea2;
  background-color: transparent;
}

.footer {
  width: 100%;
  background: #234ea2;
  color: white;
  text-align: center;
}

.main-title {
  font-size: 1.3em;
}

.bottom-content {
  font-size: 0.8em;
  margin-bottom: 1rem !important;
}

.footerUl,
.footerLi {
  list-style-type: none;
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

.emailHref {
  text-decoration: none;
  color: white;
}

.emailHref:hover {
  color: white;
}

.ourBusiness .productCol {
  padding: 1em 1em 1em 1em;
  max-height: 400px;
  max-width: 400px;
}

.full-spinner {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: gainsboro;
  opacity: 50%;
}

.spinner-border {
  color: #234ea2;
  margin: 0;
  position: absolute;
  top: 50%;
}

.miniNavBar {
  height: 100vh;
  position: fixed;
  top: 28%;
  left: 23px;
}

.miniNavBar div {
  width: 10px;
  height: 10vh;
  margin-bottom: 2px;
  background-color: #234ea2;
}

.navBarButton {
  position: fixed;
  top: 23%;
  left: 10px;
  background-color: #234ea2;
}

.button-tooltip {
  cursor: pointer;
}

.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn {
  opacity: 1;
  transition: width 0.1s, height 0.1s, opacity 0.1s 0.1s;
}

/* For Mobile Phones */
@media only screen and (max-width: 768px) {
  .ourBusiness .productCol {
    max-height: 200px;
    max-width: 200px;
  }
}

.servicesCol span {
  padding-right: 1rem;
  text-decoration: underline;
  cursor: pointer;
}

.top-background {
  background-image: linear-gradient(
    to right bottom,
    #234ea2,
    #476db5,
    #698cc7,
    #8eacd7,
    #b6cce7
  );
}

.serviceTitle {
  color: black;
  font-size: 2vh;
}

.serviceBody {
  color: black;
  text-align: justify;
}

.businesses-card .card-img-overlay {
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 3em;
  color: white;
  word-spacing: 100vw;
}

.businesses-card {
  text-align: center;
}

.businesses-card:hover {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

.businesses-card:hover .card-img-overlay {
  font-size: 3.5em;
  color: #234ea2;
  background-color: rgba(255, 255, 255, 0.8);
}

/* For Mobile Phones */
@media only screen and (max-width: 768px) {
  .businesses-card {
    font-size: 1em;
  }
}

