@import url("https://fonts.googleapis.com/css?family=Noto+Sans");

@import url(//fonts.googleapis.com/earlyaccess/notosanssc.css);

body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  font-family: "Noto Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.bold {
  font-weight: bold;
}

.medium {
  font-weight: 500;
}

.italic {
  font-style: italic;
}

.noto-sans-sc {
  font-family: "Noto Sans SC", sans-serif;
}

/* For Mobile Phones */
@media only screen and (max-width: 768px) {
}
