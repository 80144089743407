.section {
  width: 100%;
  text-align: center;
  justify-content: center;
}

.sectionTitle {
  font-size: 4vh;
  color: #234ea2;
  margin-bottom: 1rem;
}

.sectionBody {
  font-size: 2.5vh;
  color: black;
}

.discoverStory {
  line-height: 25px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.carousel-container {
  padding: 10px 0 20px 0;
}

.react-multi-carousel-item {
  padding: 0 25px 0 25px;
}

.card {
  cursor: pointer;
}

.productCard {
  max-width: 400px;
  max-height: 400px;
}

.card-img-overlay {
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 1.7em;
  color: white;
  word-spacing: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-multi-carousel-item:hover {
  transform: scale(1.02);
}

.serviceCard {
  max-width: 600px;
  max-height: 600px;
}

.serviceCard:hover {
  transform: scale(1.01);
}

.serviceCard:hover .card-img-overlay {
  font-size: 1.75em;
  color: #234ea2;
  background-color: rgba(255, 255, 255, 0.8);
}

.readMoreBtn:hover {
  transform: scale(1.01);
}

/* For Mobile Phones */
@media only screen and (max-width: 768px) {
  .serviceCard {
    max-width: 300px;
    max-height: 300px;
    font-size: 0.8em;
  }

  .serviceCard:hover .card-img-overlay {
    font-size: 1.75em;
  }
}
