.contentPart {
  padding-bottom: 0.5rem !important;
}

.contentPart p {
  line-height: 20px;
}

.aboutUsSectionBody {
  color: black;
  font-size: 1em;
}

.mileStoneBodyContainer {
  width: 100%;
  display: flex;
}

.timeline-item-title {
  font-size: 3vh !important;
  color: #234ea2 !important;
  word-spacing: 100vw;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-description {
  font-size: 2vh !important;
  color: black !important;
  align-items: flex-start !important;
}

.timeline-card-content {
  min-height: 200px !important;
}

.card-content-wrapper {
  width: 70% !important;
}

/* For Desktop and Tablet */
@media only screen and (min-width: 769px) {
  .card-description {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-left: 15px !important;
    line-height: 30px;
  }
}

/* For Mobile Phones */
@media only screen and (max-width: 768px) {
  .mileStoneTitle {
    width: 100%;
    text-align: center;
  }

  .timeline-item-title {
    font-size: 2vh !important;
  }

  .css-mpnodf-TimelineTitleWrapper,
  .css-oq5ppo {
    width: 37% !important;
  }

  .card-content-wrapper {
    width: 53% !important;
  }

  .mobileVersion p {
    text-align: justify;
    text-justify: inter-word;
    text-indent: 30px;
  }
}
