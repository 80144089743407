.businessOpp ul {
  list-style-position: inside;
  padding-inline-start: 0;
  color: black;
  font-size: 1em;
  line-height: 30px;
}

.black {
  color: black;
  font-size: 1em;
}
