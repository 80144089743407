.inputBox {
  width: 100%;
  border: 0.5px solid #234ea2;
}

.submitButton {
  float: right;
  color: #234ea2;
  border: 0.5px solid #234ea2;
  background-color: transparent;
}
