.whatsapp-button {
  position: fixed;
  z-index: 999;
  right: 20px;
  bottom: 100px;
}

.whatsapp-icon {
  font-size: 1.5em;
}
