.nav-link,
.navDropDown .nav-link,
.navDropDownItem {
  color: #ffffff !important;
  font-size: 1em;
  padding-right: 10px !important;
}

.nav-link:hover,
.navDropDown .nav-link:hover {
  font-weight: bold;
  /* color: forestgreen !important; */
}

.navDropDownItem:hover {
  background-color: #0d6efd;
}

.dropdown-menu {
  width: auto;
  background-color: #4571c9;
  margin-top: -5px !important;
}

.mmtLogo {
  background-color: white;
  margin-top: -50px;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 16% 100%, 0% 44%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 16% 100%, 0% 44%);
}
/* For Mobile Phones */
@media only screen and (max-width: 768px) {
  .dropdown-menu {
    width: 100%;
    text-align: right !important;
    min-width: 40% !important;
  }
}
