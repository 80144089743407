.businesses-card .card-img-overlay {
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 3em;
  color: white;
  word-spacing: 100vw;
}

.businesses-card {
  text-align: center;
}

.businesses-card:hover {
  transform: scale(1.01);
}

.businesses-card:hover .card-img-overlay {
  font-size: 3.5em;
  color: #234ea2;
  background-color: rgba(255, 255, 255, 0.8);
}

/* For Mobile Phones */
@media only screen and (max-width: 768px) {
  .businesses-card {
    font-size: 1em;
  }
}
