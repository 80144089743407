.footer {
  width: 100%;
  background: #234ea2;
  color: white;
  text-align: center;
}

.main-title {
  font-size: 1.3em;
}

.bottom-content {
  font-size: 0.8em;
  margin-bottom: 1rem !important;
}

.footerUl,
.footerLi {
  list-style-type: none;
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

.emailHref {
  text-decoration: none;
  color: white;
}

.emailHref:hover {
  color: white;
}
